import React from 'react'
import classnames from 'classnames/bind'
import styles from './style.module.scss'

// Components / Views
import logoSrc from '@/assets/images/static/logo.png'
import Typography from '@/basicComponents/Typography'
import Form from '@/views/Auth/views/Login/components/Form'

const cx = classnames.bind(styles)

function Login(props) {
  return (
    <div className={cx('login')}>
      <img src={logoSrc} width='78' height='78' />
      <Typography.Title level='h1' align='center' className={cx('login__title')}>
        外貿協會影音資料庫
      </Typography.Title>
      <div className={cx('login__form')}>
        <Form />
      </div>
    </div>
  )
}

export default Login
